
const miljoesanering = {

  registerCalculateWeight: function (element) {
    let weightInput = $(element).find('.miljoesaneringskjema_fraction_values_total_weight input');
    let quantityInput = $(element).find('.miljoesaneringskjema_fraction_values_quantity input');
    let defaultWeight = weightInput.data().defaultWeight;

    quantityInput.on('keyup', (e) => {
      let quantity = quantityInput.val();
      let calculatedWeight = null;
      if (quantity) {
        calculatedWeight = (defaultWeight * quantity).toFixed(2);
      }
      $(weightInput).val(calculatedWeight);
    });
  },

  fadeInCleanNewRow: function (fraction_row) {
    fraction_row.find('input').not(':hidden').val('');
    fraction_row.hide().fadeIn();
    this.newFractionRowRegisterListeners(fraction_row);
  },
  addFractionRow: function(e) {
    let fraction_form = $(e.target).closest('.fraction-form');
    let fraction_form_html = fraction_form.prop('outerHTML');
    let ms_regex = /miljoesaneringskjema\[fraction_values_attributes\]\[(\d+)\]/g;
    let new_id = Date.now();
    let new_identifier = 'miljoesaneringskjema[fraction_values_attributes][' + new_id + ']';
    let new_form_row_html = fraction_form_html.replace(ms_regex, new_identifier);
    fraction_form.after(new_form_row_html);
    this.fadeInCleanNewRow(fraction_form.next());
    },

  newFractionRowRegisterListeners: function(element) {
    if(element.hasClass('calculate')) {
      this.registerCalculateWeight(element);
    }
    this.registerAddRowListener(element.find('a.js--add-fraction-row'));
    this.setRemoveButtonsVisibility();
  },

  disabledRemoveClick: false,

  removeFractionRow: function(e) {
    if(this.disabledRemoveClick) {
      return true;
    }

    let field_to_remove = $(e.target).next().find('input');
    field_to_remove.val(true);
    this.disabledRemoveClick = true;
    $(e.target).closest('.fraction-form').fadeOut( { complete: () => {
        this.disabledRemoveClick = false;
        this.setRemoveButtonsVisibility();
      }
    });
  },

  registerAddRowListener: function(element) {
    element.click( (e) => {
      this.addFractionRow(e);
    });
  },

  registerRemoveRowListener: function() {
     $('.miljoesanering-fractions-form').on('click', 'a.js--remove-fraction-row', (event) => {
       this.removeFractionRow(event);
     });
  },

  setRemoveButtonsVisibility: function() {
    $('.fraction-form').each( (index, element) => {
        let fraction_key  = $(element).data('fractionKey');
        let fraction_forms = $('.fraction-form.' + fraction_key + ':visible');
        let removeButton = $(element).find('a.js--remove-fraction-row');
        if(fraction_forms.length > 1 ) {
          removeButton.show();
        } else {
          removeButton.hide();
        }
    });
  },

  // Give the user a confirmation dialog when it tries to leave the
  // website with a list in edit mode.
  trackFormChanges: function() {
    let submitted = false;
    let the_form = $("form");
    the_form.submit(function() {
      submitted = true;
    });
    the_form.trackChanges();
    window.onbeforeunload = function (e) {
      if ($(".warn-on-unload").isChanged() && !submitted){
        return true;
      }
    };
    $(document).on('turbo:before-visit', function(e) {
      if ($(".warn-on-unload").isChanged() && !submitted) {
        if(!confirm('Er du sikker på at du vil forlate siden? Du vil miste data du ikke har lagret. Tips: Trykk på Lagre-knappen.')) {
          return e.preventDefault();
        }
      }
    });
  }
};

function initMiljoesanering() {
  if($('.miljoesanering-fractions-form').length) {
    $('.calculate').each((index, element) => {
      miljoesanering.registerCalculateWeight(element);
    });
    miljoesanering.registerAddRowListener($('a.js--add-fraction-row'));
    miljoesanering.registerRemoveRowListener();
    miljoesanering.setRemoveButtonsVisibility();
    miljoesanering.trackFormChanges();
  }
}

export { initMiljoesanering };
