function toggleText() {
    const button = document.getElementById('rediger-forsiden');
    document.querySelector('.admin-panel').classList.toggle('hidden');

    button.innerText = (button.innerText === "REDIGER FORSIDEN") ? "SKJUL ADMINISTRASJONSPANELET" : "REDIGER FORSIDEN";
}

function adminPanel() {
    const button = document.getElementById('rediger-forsiden');
    if(button) {
        button.addEventListener('click', toggleText);
    }
    $('a.js--show-versions').click(function() {
        document.querySelector('#show-versions').classList.toggle('hidden');
    });
}

export { adminPanel };
