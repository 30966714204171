function print() {
    window.print()
}

function printPage() {
    let el = document.getElementById('print');

    if(el) {
        el.addEventListener('click', print);
    }
}

export { printPage };
