function toggleLeverandoerField() {
    const leverandoerField = document.querySelector('.leverandoer');
    const chosenPressType = document.getElementById('bop_press_type');

    if(chosenPressType.value === 'mobil presse') {
        leverandoerField.classList.remove('hidden');
    } else {
        leverandoerField.classList.add('hidden');
    }
}
function pressType() {
    const element = document.querySelector('.press_type');
    if(element) {
        element.addEventListener('change', toggleLeverandoerField)
    }
}

export { pressType };
