// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "notice" ]

  deleteNotice(e) {
    this.deleteElement(e.target.parentElement.parentElement)
  }

  noticeTargetConnected() {
    const lastElementPosition = this.noticeTargets.length - 1
    const newestNotice = this.noticeTargets[lastElementPosition]
    setTimeout(() => this.deleteElement(newestNotice),3000)
  }

  deleteElement(element) {
    if (!document.contains(element)) {
      return;
    }
    element.classList.add("clicked-button")
    element.addEventListener("animationend", () => element.remove())
    // TODO: kanskje legge til en removeEventListener, fordi jeg vet ikke om element.remove() fjærner absolut alt.
  }
}

