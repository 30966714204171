import { Controller } from "stimulus"
import { daterangepicker } from "../vendor/daterangepicker";

export default class extends Controller {
    static targets = [ "submit" ]

    connect() {
        document.addEventListener("turbo:submit-end", () => {
            setTimeout(() => {
                const datepicker = document.querySelector("div.daterangepicker")
                daterangepicker()
                datepicker.remove()
            },50)
        })
    }
}