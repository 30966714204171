function pdfUpload() {
    const attachmentDiv = $("#attachment")[0]
    const removePdfField = $("#fylkesmannsrapport_remove_pdf")[0]
    let attachedFile = $("#original-file-name")[0]
    let fileInput = $("#fylkesmannsrapport_pdf_file")[0]

    function toggleShowing(){
        $("#pdf-uploaded")[0].classList.toggle("d-none")
        $("#upload-btn")[0].classList.toggle("d-none")
    }

    function createNewInput(){
        let input = document.createElement("input")
        input.classList.add("hidden")
        input.setAttribute("id", "fylkesmannsrapport_pdf_file")
        input.setAttribute("type", "file")
        input.setAttribute("accept", "application/pdf")
        input.setAttribute("name", "fylkesmannsrapport[pdf_file]")
        return input
    }

    function replaceInput() {
        let newInput = createNewInput()
        attachmentDiv.removeChild(fileInput)
        attachmentDiv.appendChild(newInput)
        fileInput.removeEventListener("change", showAttachment)
        fileInput = newInput
        fileInput.addEventListener("change", showAttachment)
    }

    function showAttachment() {
        if(attachedFile !== undefined) {attachedFile.classList.add("d-none")}
        $("#file-name")[0].textContent = "Vedlegg: " + fileInput.files['0']['name']
        toggleShowing()
    }

    if(fileInput !== undefined){fileInput.addEventListener("change", showAttachment)}

    $("#remove-pdf").on("click", function (){
        removePdfField.setAttribute("value", "true")
        toggleShowing()
        replaceInput()
    })
}

export { pdfUpload }