function initBrandsSelector() {
    if ($('#battery_registration_number').length) {
        const id = $('#battery_registration_number')
        id.keyup(function (value) {
            const registrationNumber = id.val()
            if (registrationNumber.length === 7) {
                fetch(`/kjoeretoeys/merke.json?registration_number=${registrationNumber}`)
                    .then(response => response.json())
                    .then(data => {
                        if (!data.brand) {
                            id.siblings('.form-text').text('Bilmerke ikke funnet.').removeClass('text-muted').addClass('red')
                        } else {
                            id.siblings('.form-text').text(`Bilmerke funnet: ${data.brand}`).removeClass('red').addClass('text-muted')
                        }
                        if ($('#battery_car_brand').val().length === 0) {
                            $('#battery_car_brand').val(data.brand)
                        }
                    })
            }
        })
    }
}

export {initBrandsSelector}

