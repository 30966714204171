/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


import 'bootstrap';
import 'bootstrap-daterangepicker';
import '@hotwired/turbo-rails';

import { tinyMce } from "../vendor/tinyMce";
import { daterangepicker } from "../vendor/daterangepicker";
import { printPage } from "../custom/printPage";
import { pressType } from "../custom/bopPressType";
import { adminPanel } from "../custom/adminPanel";
import { companyCreation } from "../custom/companyCreation";
import { fileUpload, showUploadButton } from "../custom/fileUpload";
import { initMiljoesanering } from "../custom/miljoesanering";
import "../custom/track_form_changes";
import { submitOnChange } from "../custom/submit_on_change";
import { sumNewFractionDisposalRows } from "../custom/new_fraction_disposal_summary";
import { initBrandsSelector } from "../custom/brandsSelector";
import { pdfUpload } from "../custom/pdfUpload";
import {enablePopover} from "../custom/enablePopover";

document.addEventListener("turbo:load", function () {
    pressType();
    adminPanel();
    companyCreation();
    tinyMce();
    daterangepicker();
    printPage();
    initMiljoesanering();
    initBrandsSelector();
    submitOnChange();
    fileUpload();
    pdfUpload();
    showUploadButton();
    sumNewFractionDisposalRows();
    enablePopover()
});


import "controllers"
