
function calculateSum(totalField, array) {
    var total = 0.0;
    array.forEach(function(item) {
        if(parseFloat(item.value))
            total += parseFloat(item.value);
    });
    totalField.textContent = total.toFixed(3);
}

function sumNewFractionDisposalRows() {
    const totalField = document.getElementById('total');
    const array = document.querySelectorAll('input[type=number]');

    if(totalField){
        calculateSum(totalField, array);
        array.forEach(function (item) {
            item.addEventListener('keyup', function(){

                calculateSum(totalField, array);
            }, false);
        });
    };
}

export { sumNewFractionDisposalRows };
