function submitFormFunction () {
  var parentForm = $(this).parent().closest('form');
  if (parentForm && parentForm.length > 0)
    return parentForm.submit();
}

function submitOnChange() {
  $('.submit-on-change').on('change', submitFormFunction)
                        .on('apply.daterangepicker', submitFormFunction)
}
export { submitOnChange };
