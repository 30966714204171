import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';

const ajax = {
    url: '/brreg_companies',
    data: function (params) {
        return {
            term: params.term
        };
    },
    dataType: 'json',
    delay: 500,
    processResults: function (data, params) {
        return {
            results: _.map(data, function (el) {
                return {
                    id: el.id,
                    name: el.name,
                    organisation_number: el.organisation_number
                };
            })
        };
    },
    cache: true
};

function companyCreation() {
  function initSelect(cssId) {
    let noResultsUrl = $(cssId).closest('form').data('newModelExceptionPath');

    $(cssId).select2({
      // Select2 har en bug som ikke lar deg både spesifisere et annet språk enn engelsk OG override en enkelt feilmelding :(
      language: {
        "noResults": function(){
          return "Fant ingen selskaper med dette navnet. Er du sikker på at du har skrevet inn navn, og at du har stavet det riktig? Hvis ja: &nbsp;<a href=" + noResultsUrl + " class='btn btn-danger btn-round btn-sm'>Opprett likevel</a>";
        },
        "inputTooShort": function(e) {
          const t = e.minimum - e.input.length;
          return "Vennligst skriv inn " + t + " tegn til"
        }
      },
      ajax: ajax,
      escapeMarkup: function(markup) {
        return markup;
      },
      minimumInputLength: 3,
      templateResult: function(item) {
        return item.name;
      },
      templateSelection: function(item) {
        return item.name;
      },
    }).select2('open').select2('focus');
  }
  initSelect(".company-chooser");
}

export { companyCreation };

