import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/modern/theme';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';

import 'tinymce/skins/lightgray/skin.min.css';


function tinyMce() {
    tinymce.remove();
    tinymce.init({
        content_style: "body {font-family: 'Roboto-Regular',sans-serif;}",
        selector: 'textarea.tinymce',
        height: 500,
        menubar: false,
        plugins: [
            'table', 'lists', 'link'
        ],
        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link table',
        language: 'nb_NO',
        skin: false
    });
}

tinymce.addI18n('nb_NO',{
    "Redo": "Utf\u00f8r likevel",
    "Undo": "Angre",
    "Cut": "Klipp ut",
    "Copy": "Kopier",
    "Paste": "Lim inn",
    "Select all": "Marker alt",
    "New document": "Nytt dokument",
    "Ok": "OK",
    "Cancel": "Avbryt",
    "Visual aids": "Visuelle hjelpemidler",
    "Bold": "Halvfet",
    "Italic": "Kursiv",
    "Underline": "Understreket",
    "Strikethrough": "Gjennomstreket",
    "Superscript": "Hevet skrift",
    "Subscript": "Senket skrift",
    "Clear formatting": "Fjern formateringer",
    "Align left": "Venstrejustert",
    "Align center": "Midtstilt",
    "Align right": "H\u00f8yrejustert",
    "Justify": "Juster alle linjer",
    "Bullet list": "Punktliste",
    "Numbered list": "Nummerliste",
    "Decrease indent": "Reduser innrykk",
    "Increase indent": "\u00d8k innrykk",
    "Close": "Lukk",
    "Formats": "Stiler",
    "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X\/C\/V keyboard shortcuts instead.": "Nettleseren din st\u00f8tter ikke direkte tilgang til utklippsboken. Bruk istedet tastatur-snarveiene Ctrl+X\/C\/V, eller Cmd+X\/C\/V p\u00e5 Mac.",
    "Headers": "Overskrifter",
    "Header 1": "Overskrift 1",
    "Header 2": "Overskrift 2",
    "Header 3": "Overskrift 3",
    "Header 4": "Overskrift 4",
    "Header 5": "Overskrift 5",
    "Header 6": "Overskrift 6",
    "Headings": "Overskrifter",
    "Heading 1": "Overskrift 1",
    "Heading 2": "Overskrift 2",
    "Heading 3": "Overskrift 3",
    "Heading 4": "Overskrift 4",
    "Heading 5": "Overskrift 5",
    "Heading 6": "Overskrift 6",
    "Div": "Delblokk <div>",
    "Pre": "Definert <pre>",
    "Code": "Kode <code>",
    "Paragraph": "Avsnitt <p>",
    "Blockquote": "Sitatblokk <blockquote>",
    "Inline": "Innkapslet <span>",
    "Blocks": "Blokker",
    "Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "Lim inn er n\u00e5 i ren-tekst modus. Kopiert innhold vil bli limt inn som ren tekst inntil du sl\u00e5r av dette valget.",
    "Font Family": "Skriftsnitt",
    "Font Sizes": "St\u00f8rrelse",
    "Class": "Klasse",
    "Browse for an image": "S\u00f8k etter bilde",
    "OR": "ELLER",
    "Drop an image here": "Slipp et bilde her",
    "Upload": "Last opp",
    "Block": "Blokk",
    "Align": "Juster",
    "Default": "Normal",
    "Circle": "\u00c5pen sirkel",
    "Disc": "Fylt sirkel",
    "Square": "Fylt firkant",
    "Lower Alpha": "Minuskler",
    "Lower Greek": "Greske minuskler",
    "Lower Roman": "Romerske minuskler",
    "Upper Alpha": "Versaler",
    "Upper Roman": "Romerske versaler",
    "Anchor": "Anker",
    "Name": "Navn",
    "Id": "Id",
    "Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.": "Id burde starte med en bokstav, bare fulgt av bokstaver, nummer, streker, punktum, koloner eller understreker.",
    "You have unsaved changes are you sure you want to navigate away?": "Du har ikke arkivert endringene. Vil du fortsette uten \u00e5 arkivere?",
    "Restore last draft": "Gjenopprett siste utkast",
    "Special character": "Spesialtegn",
    "Source code": "Kildekode",
    "Insert\/Edit code sample": "Sett inn\/endre kodeeksempel",
    "Language": "Spr\u00e5k",
    "Code sample": "Kodeeksempel",
    "Color": "Farge",
    "R": "R",
    "G": "G",
    "B": "B",
    "Left to right": "Venstre til h\u00f8yre",
    "Right to left": "H\u00f8yre til venstre",
    "Emoticons": "Hum\u00f8rfjes",
    "Document properties": "Dokumentegenskaper",
    "Title": "Tittel",
    "Keywords": "N\u00f8kkelord",
    "Description": "Beskrivelse",
    "Robots": "Roboter",
    "Author": "Forfatter",
    "Encoding": "Tegnkoding",
    "Fullscreen": "Fullskjerm",
    "Action": "Handling",
    "Shortcut": "Snarvei",
    "Help": "Hjelp",
    "Address": "Adresse",
    "Focus to menubar": "Fokus p\u00e5 menylinje",
    "Focus to toolbar": "Fokus p\u00e5 verkt\u00f8ylinje",
    "Focus to element path": "Fokus p\u00e5 elementsti",
    "Focus to contextual toolbar": "Fokus p\u00e5 kontekstuell verkt\u00f8ylinje",
    "Insert link (if link plugin activated)": "Sett inn lenke (dersom lenketillegg er aktivert)",
    "Save (if save plugin activated)": "Lagre (dersom lagretillegg er aktivert)",
    "Find (if searchreplace plugin activated)": "Finn (dersom tillegg for s\u00f8k og erstatt er aktivert)",
    "Plugins installed ({0}):": "Installerte tillegg ({0}):",
    "Premium plugins:": "Premiumtillegg:",
    "Learn more...": "Les mer ...",
    "You are using {0}": "Du bruker {0}",
    "Plugins": "Tillegg",
    "Handy Shortcuts": "Nyttige snarveier",
    "Horizontal line": "Horisontal linje",
    "Insert\/edit image": "Sett inn\/endre bilde",
    "Image description": "Bildebeskrivelse",
    "Source": "Bildelenke",
    "Dimensions": "Dimensjoner",
    "Constrain proportions": "Behold proporsjoner",
    "General": "Generelt",
    "Advanced": "Avansert",
    "Style": "Stil",
    "Vertical space": "Vertikal marg",
    "Horizontal space": "Horisontal marg",
    "Border": "Ramme",
    "Insert image": "Sett inn bilde",
    "Image": "Bilde",
    "Image list": "Bildeliste",
    "Rotate counterclockwise": "Roter mot venstre",
    "Rotate clockwise": "Roter mot h\u00f8yre",
    "Flip vertically": "Speilvend vertikalt",
    "Flip horizontally": "Speilvend horisontalt",
    "Edit image": "Rediger bilde",
    "Image options": "Bilde innstillinger",
    "Zoom in": "Zoom inn",
    "Zoom out": "Zoom ut",
    "Crop": "Beskj\u00e6r",
    "Resize": "Skaler",
    "Orientation": "Orientering",
    "Brightness": "Lysstyrke",
    "Sharpen": "Skarphet",
    "Contrast": "Kontrast",
    "Color levels": "Fargeniv\u00e5",
    "Gamma": "Gamma",
    "Invert": "Inverter",
    "Apply": "Utf\u00f8r",
    "Back": "Tilbake",
    "Insert date\/time": "Sett inn dato\/tid",
    "Date\/time": "Dato\/tid",
    "Insert link": "Sett inn lenke",
    "Insert\/edit link": "Sett inn\/endre lenke",
    "Text to display": "Tekst som skal vises",
    "Url": "Url",
    "Target": "M\u00e5l",
    "None": "Ingen",
    "New window": "Nytt vindu",
    "Remove link": "Fjern lenke",
    "Anchors": "Anker",
    "Link": "Lenke",
    "Paste or type a link": "Lim inn eller skriv en lenke",
    "The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "Oppgitte URL ser ut til \u00e5 v\u00e6re en epost-adresse. \u00d8nsker du \u00e5 sette inn p\u00e5krevet mailto: prefiks forran epost-adressen?",
    "The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?": "Oppgitt URL ser ut til \u00e5 v\u00e6re en e-postadresse. \u00d8nsker du \u00e5 sette inn p\u00e5krevd mailto:-prefiks foran e-postadressen?",
    "Link list": "Lenkeliste",
    "Insert video": "Sett inn video",
    "Insert\/edit video": "Sett inn\/rediger video",
    "Insert\/edit media": "Sett inn\/endre media",
    "Alternative source": "Alternativ kilde",
    "Poster": "Plakatbilde",
    "Paste your embed code below:": "Lim inn  inkluderings-koden nedenfor",
    "Embed": "Inkluder",
    "Media": "Media",
    "Nonbreaking space": "Hardt mellomrom",
    "Page break": "Sideskifte",
    "Paste as text": "Lim inn som tekst",
    "Preview": "Forh\u00e5ndsvisning",
    "Print": "Skriv ut",
    "Save": "Arkiver",
    "Find": "Finn",
    "Replace with": "Erstatt med",
    "Replace": "Erstatt",
    "Replace all": "Erstatt alle",
    "Prev": "Forrige",
    "Next": "Neste",
    "Find and replace": "Finn og erstatt",
    "Could not find the specified string.": "Kunne ikke finne den spesifiserte teksten",
    "Match case": "Match store og sm\u00e5 bokstaver",
    "Whole words": "Hele ord",
    "Spellcheck": "Stavekontroll",
    "Ignore": "Ignorer",
    "Ignore all": "Ignorer alle",
    "Finish": "Avslutt",
    "Add to Dictionary": "Legg til i ordliste",
    "Insert table": "Sett inn tabell",
    "Table properties": "Tabell egenskaper",
    "Delete table": "Slett tabell",
    "Cell": "Celle",
    "Row": "Rad",
    "Column": "Kolonne",
    "Cell properties": "Celle egenskaper",
    "Merge cells": "Sl\u00e5 sammen celler",
    "Split cell": "Splitt celle",
    "Insert row before": "Sett inn rad f\u00f8r",
    "Insert row after": "Sett in rad etter",
    "Delete row": "Slett rad",
    "Row properties": "Rad egenskaper",
    "Cut row": "Klipp ut rad",
    "Copy row": "Kopier rad",
    "Paste row before": "Lim inn rad f\u00f8r",
    "Paste row after": "Lim inn rad etter",
    "Insert column before": "Sett inn kolonne f\u00f8r",
    "Insert column after": "Sett inn kolonne etter",
    "Delete column": "Slett kolonne",
    "Cols": "Kolonner",
    "Rows": "Rader",
    "Width": "Bredde",
    "Height": "H\u00f8yde",
    "Cell spacing": "Celleavstand",
    "Cell padding": "Cellemarg",
    "Caption": "Tittel",
    "Left": "Venstre",
    "Center": "Midtstilt",
    "Right": "H\u00f8yre",
    "Cell type": "Celletype",
    "Scope": "Omfang",
    "Alignment": "Justering",
    "H Align": "H Justering",
    "V Align": "V Justering",
    "Top": "Topp",
    "Middle": "Midten",
    "Bottom": "Bunn",
    "Header cell": "Topptekst-celle",
    "Row group": "Radgruppe",
    "Column group": "Kolonnegruppe",
    "Row type": "Rad-type",
    "Header": "Topptekst",
    "Body": "Br\u00f8dtekst",
    "Footer": "Bunntekst",
    "Border color": "Rammefarge",
    "Insert template": "Sett inn mal",
    "Templates": "Maler",
    "Template": "Mal",
    "Text color": "Tekstfarge",
    "Background color": "Bakgrunnsfarge",
    "Custom...": "Tilpass...",
    "Custom color": "Tilpasset farge",
    "No color": "Ingen farge",
    "Table of Contents": "Innholdsfortegnelse",
    "Show blocks": "Vis blokker",
    "Show invisible characters": "Vis skjulte tegn",
    "Words: {0}": "Antall ord: {0}",
    "{0} words": "{0} ord",
    "File": "Arkiv",
    "Edit": "Rediger",
    "Insert": "Sett inn",
    "View": "Vis",
    "Format": "Format",
    "Table": "Tabell",
    "Tools": "Verkt\u00f8y",
    "Powered by {0}": "Redigert med {0}",
    "Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "Tekstredigering. Tast ALT-F9 for meny. Tast ALT-F10 for verkt\u00f8ys-rader. Tast ALT-0 for hjelp."
});

export { tinyMce };
