import $ from 'jquery';
import moment from 'moment';
import 'bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';


function daterangepicker() {

    let locale = {
        format: 'DD.MM.YYYY',
        applyLabel: "Velg",
        cancelLabel: "Avbryt",
        customRangeLabel: "Annen",
        fromLabel: "Fra",
        toLabel: "Til",
        daysOfWeek: ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
        monthNames: ["Januar", "Februar", "Mars", "April", "Mai", "Juni",
            "Juli", "August", "September", "Oktober", "November", "Desember"],
    };

    let maxDate = moment();

    $('input[name="daterange"]').daterangepicker({
        autoUpdateInput: false,
        locale: locale,
        applyButtonClasses: "btn-success btn-round",
        cancelClass: "btn-default btn-round",
        opens: 'center',
        startDate: moment().startOf('month'),
        endDate: moment(),
        ranges: {
            '1. - 7. d.m.': [moment().startOf('month'), moment().startOf('month').add(6, 'days')],
            '8. - 14. d.m.': [moment().startOf('month').add(7, 'days'), moment().startOf('month').add(13, 'days')],
            '15. - 21. d.m.': [moment().startOf('month').add(14, 'days'), moment().startOf('month').add(20, 'days')],
            '22. - ut måneden': [moment().startOf('month').add(21, 'days'), moment().endOf('month')],
            'Denne måneden': [moment().startOf('month'), moment()],
            'Forrige måned': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            '2 måneder siden': [moment().subtract(2, 'month').startOf('month'), moment().subtract(2, 'month').endOf('month')],
            'Hittil i år': [moment().startOf('year'), moment()],
            'I fjor': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
        },
        maxDate: maxDate
    });

    $('input[name="daterange"]').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY'));
    });

    $('input[name="daterange"]').on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
    });

    $('.singledatepicker').daterangepicker({
        locale: locale,
        singleDatePicker: true,
        maxDate: maxDate,
        autoUpdateInput: false
    });

    $('.singledatepicker').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('DD.MM.YYYY'));
    });

    $('.singledatepicker').on('change', function(ev) {
        ev.target.value = moment(ev.target.value, "DD/MM/YYYY").format("DD.MM.YYYY")
    });

    $('.singledatepicker').on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
    });

    $('input[name="monthrange"]').daterangepicker({
        autoUpdateInput: false,
        locale: locale,
        applyButtonClasses: "btn-success btn-round",
        cancelClass: "btn-default btn-round",
        opens: 'center',
        startDate: moment().startOf('month'),
        endDate: moment(),
        ranges: {
            '2 måneder siden': [moment().subtract(2, 'month').startOf('month'), moment().subtract(2, 'month').endOf('month')],
            'Forrige måned': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'Denne måneden': [moment().startOf('month'), moment()],
            'Hittil i år': [moment().startOf('year'), moment()],
            'I fjor': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
        },
        maxDate: maxDate
    });

    $('input[name="monthrange"]').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY'));
    });

    $('input[name="monthrange"]').on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
    });

};

export { daterangepicker };
